import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Form, Input, Button, Alert } from 'antd';
import axios from 'axios';
const { Title, Text } = Typography;

import styles from '../../stylesheets/ForgotPassword.module.css';

const ForgotPassword = () => {
  const emailInputRef = useRef(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  const handleSubmit = async (items) => {
    setSubmitting(true);

    const params = {
      email: items.email,
    };
    const response = await axios.post('/api/password-manager/send-email', params);

    setSubmitting(false);
    navigate('/forgot-password-confirmation');
  }

  return (
    <div className={styles.container}>
      <div className={styles.forgotPasswordContainer}>
        <div className={styles.instructLogoContainer}>
          <img
            src='https://development-instruct.s3.us-east-2.amazonaws.com/instruct-logo.jpg'
            className={styles.instructLogo}
          />
        </div>
        <div className={styles.titleContainer}>
          <Title level={2} className={styles.title}>
            Forgot password?
          </Title>
        </div>
        <div className={styles.msgContainer}>
          <Text>
            Enter your email and we’ll send you a link to reset your password.
          </Text>
        </div>

        <Form 
          layout='vertical' 
          className={styles.form}
          requiredMark={false}
          onFinish={handleSubmit}
        >
          <Form.Item
            label='Email'
            name='email'
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
            className={styles.emailInput}
          >
            <Input ref={emailInputRef} />
          </Form.Item>

          <Form.Item>
            <Button 
              size='large'
              htmlType='submit'
              type='primary'
              className={styles.submitBtn}
              loading={isSubmitting}
            >
              Send password reset email
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;