import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Form, Input, Button, Alert } from 'antd';
import axios from 'axios';
const { Title, Text } = Typography;

import { ResetPasswordApiStatusCodes } from '../lib/constants';
import styles from '../../stylesheets/ResetPassword.module.css';

const ResetPassword = () => {
  const navigate = useNavigate();
  const passwordInputRef = useRef(null);
  const [isResetting, setResetting] = useState(false);
  const [hasError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  }, []);

  const handleResetPassword = async (items) => {
    setResetting(true);

    const urlParams = new URLSearchParams(location.search);

    const params = {
      new_password: items.newPassword,
      new_password_confirmation: items.newPasswordConfirmation,
      reset_password_token: urlParams.get('token'),
    };

    const response = await axios.post('/api/password-manager/reset-password', params);

    setResetting(false);
    switch(response.data.status) {
      case ResetPasswordApiStatusCodes.PASSWORD_TOO_SHORT:
        setError(true);
        setErrorMessage('Password must be at least 8 characters.');
        break;
      case ResetPasswordApiStatusCodes.PASSWORDS_DO_NOT_MATCH:
        setError(true);
        setErrorMessage('Passwords do not match.');
        break;
      case ResetPasswordApiStatusCodes.INVALID_TOKEN:
        setError(true);
        setErrorMessage('Token is invalid.');
        break;
      case ResetPasswordApiStatusCodes.SUCCESS:
        setError(false);
        setErrorMessage(null);
        navigate('/reset-password-success');
        break;
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.resetPasswordContainer}>
      {
        hasError &&
          <Alert 
            type='error'
            message={errorMessage}
            className={styles.errorAlert}
          />
        }
        <div className={styles.instructLogoContainer}>
          <img
            src='https://development-instruct.s3.us-east-2.amazonaws.com/instruct-logo.jpg'
            className={styles.instructLogo}
          />
        </div>
        <div className={styles.titleContainer}>
          <Title level={2} className={styles.title}>
            Reset password
          </Title>
        </div>
        <div className={styles.msgContainer}>
          <Text>
            Password must contain at least 8 characters.
          </Text>
        </div>

        <Form 
          layout='vertical' 
          className={styles.form}
          onFinish={handleResetPassword}
          requiredMark={false}
        >
          <Form.Item
            label='Password'
            name='newPassword'
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input.Password ref={passwordInputRef} />
          </Form.Item>

          <Form.Item
            label='Confirm password'
            name='newPasswordConfirmation'
            rules={[
              { 
                required: true,
                message: '',
              }
            ]}
            className={styles.confirmPasswordFormItem}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button 
              size='large'
              htmlType='submit'
              type='primary'
              className={styles.submitBtn}
              loading={isResetting}
            >
              Reset password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;