import React from 'react';
import { Modal, Typography } from 'antd';
import { AudioOutlined, VideoCameraOutlined, LockFilled } from '@ant-design/icons';
const { Title } = Typography;

import styles from '../../stylesheets/BrowserPermissionsDeniedModal.module.css';

const BrowserPermissionsDeniedModal = (props) => {
  const handleRefreshPage = () => {
    window.location.reload();
  }

  return(
    <Modal
      open={props.isOpen} 
      onOk={handleRefreshPage}
      closeable={true}
      onCancel={props.handleClose}
      cancelButtonProps={{ className: styles.cancelButton }}
      okText='I have granted these permissions'
      okButtonProps={{ className: styles.okButton }}
      classNames={{
        content: styles.content,
        footer: styles.footer,
      }}
    >
    <div className={styles.iconsContainer}>
      <div className={styles.lockedAudioIcon}>
        <AudioOutlined 
          className={styles.audioIcon}
        />
        <div className={styles.lockContainer}>   
          <LockFilled
            className={styles.lockIcon}
          />
        </div>
      </div>
      <div className={styles.lockedVideoIcon}>
        <VideoCameraOutlined
          className={styles.videoCameraIcon}
        />
        <div className={styles.lockContainer}>   
          <LockFilled
            className={styles.lockIcon}
          />
        </div>
      </div>
    </div>

    <Title 
      level={5}
      className={styles.title}
    >
      Oops! Looks like we didn’t get permission to use your microphone & camera.
    </Title>
      <p className={styles.body}>
        To begin your practice session, please enable microphone and camera access through your browser’s settings.
      </p>
  </Modal>
  )
};

export default BrowserPermissionsDeniedModal;