import React from 'react';
import { Typography, Tag } from 'antd';
const { Title, Text } = Typography;

import { TopicStatuses } from '../lib/constants'
import styles from '../../stylesheets/Card.module.css';

const Card = (props) => {
  const statusTag = () => {
    switch(props.status) {
      case TopicStatuses.COMPLETE:
        return <Tag color='green' className={styles.tag}>Complete</Tag>;
      case TopicStatuses.IN_PROGRESS:
        return <Tag color='blue' className={styles.tag}>In Progress</Tag>;
      default:
        return null;
    }
  }

  const containerClassName = () => {
    if (props.selected) {
      return styles.selectedContainer;
    } else {
      return styles.unselectedContainer;
    }
  }

  return (
    <div 
      className={`${styles.container} ${containerClassName()}`}
      onClick={() => props.handleClick(props.token)}
    >
      <Title level={4} className={styles.title}>{props.title}</Title>
      <div>
        <Text className={styles.description}>{props.description}</Text>
      </div>
      {statusTag()}
    </div>
  );
};

export default Card;