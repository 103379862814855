import React from 'react';
import { CaretRightOutlined, PauseOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import styles from '../../stylesheets/Controls.module.css';
import { VideoPlayerStates } from '../lib/constants';

const Controls = (props) => {
  const getLeftButton = () => {
    switch(props.playerState) {
      case VideoPlayerStates.NOT_PLAYING:
        return(
          <Button 
            type="primary" 
            icon={<CaretRightOutlined />}
            className={styles.playButton}
            onClick={props.handlePlay}
          >
            {props.playButtonLabel}
          </Button>
        );
      case VideoPlayerStates.PLAYING:
        return(
          <Button
            ghost
            icon={<PauseOutlined />}
            className={styles.pauseButton}
            onClick={props.handlePause}
          >
            {props.pauseButtonLabel}
          </Button>
        );
      case VideoPlayerStates.FINISHED:
        return(
          <Button
            ghost
            icon={<ReloadOutlined />}
            className={styles.replayButton}
            onClick={props.handleReplayAudio}
          >
            {props.replayButtonLabel}
          </Button>
        );
    }
  }

  const getRightButton = () => {
    switch(props.playerState) {
      case VideoPlayerStates.FINISHED:
        return(
          <Button 
            type="primary"
            onClick={props.handleFinish}
          >
            Continue to rubric
          </Button>
        );
      default:
        return(
          <Button onClick={props.handleFinish} ghost>
            Skip
          </Button>
        );
    }
  }

  return (
    <div className={styles.container}>
      {getLeftButton()}
      {getRightButton()}
    </div>
  );
};

export default Controls;