import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/RecordingControlRecording.module.css";

export default {
  "container": "RecordingControlRecording-module__container_0p2D8q__",
  "deleteButton": "RecordingControlRecording-module__deleteButton_0p2D8q__",
  "deleteContainer": "RecordingControlRecording-module__deleteContainer_0p2D8q__",
  "pause": "RecordingControlRecording-module__pause_0p2D8q__",
  "pauseContainer": "RecordingControlRecording-module__pauseContainer_0p2D8q__",
  "submitButton": "RecordingControlRecording-module__submitButton_0p2D8q__",
  "timeElapsedContainer": "RecordingControlRecording-module__timeElapsedContainer_0p2D8q__",
  "timeElapsedText": "RecordingControlRecording-module__timeElapsedText_0p2D8q__",
  "verticalDivider": "RecordingControlRecording-module__verticalDivider_0p2D8q__"
};
      