import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { 
  Typography,
  Button,
  Layout,
  Col,
} from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
const { Title, Text } = Typography;
const { Content } = Layout;

import { newPracticeAttemptPath, gatheringFeedbackPath } from '../lib/urls';
import styles from '../../stylesheets/CompletedPresentationPractice.module.css';

const CompletedPresentationPractice = () => {
  const { subtopicToken, unitToken, feedbackToken } = useParams();
  const { practiceScenario } = useOutletContext();
  const navigate = useNavigate();

  const handleReviewFeedback = () => {
    navigate(gatheringFeedbackPath(subtopicToken, unitToken, feedbackToken));
  }

  const handlePracticeAgain = () => {
    navigate(newPracticeAttemptPath(subtopicToken, unitToken));
  }

  const activeSlide = practiceScenario.practice_slides.find(slide => slide.order === 1);
  const activeSlideUrl = activeSlide ? activeSlide.url : null;

  return(
    <Content>
      <Col span={18}>
        <Title level={3} style={{ marginBottom: '15px' }}>
          {practiceScenario.title}
        </Title>
        <div className={styles.slideContainer}>
          <div className={styles.overlay} />
          <div className={styles.completedSessionWrapper}>
            <div className={styles.completedSessionContainer}>
              <Title 
                level={3} 
                id={styles.completedSessionTitle}
              >
                Great job! Your recording is saved.
              </Title>
              <Text id={styles.completedSessionBody}>
                And don’t worry—it isn’t submitted yet. After reviewing the feedback on the next page, 
                you can practice again or submit.
              </Text>
            </div>
          </div>
          <img 
            className={styles.slideImage}
            src={activeSlideUrl}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            size="large"
            className={styles.practiceAgainButton}
            onClick={handlePracticeAgain}
          >
            Practice again
          </Button>
          <Button
            type="primary"
            size="large"
            className={styles.reviewFeedbackButton}
            onClick={handleReviewFeedback}
          >
            Get feedback
          </Button>
        </div>
      </Col>
    </Content>
  );
};

export default CompletedPresentationPractice;