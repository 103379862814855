import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/WatchUnit.module.css";

export default {
  "continueBtn": "WatchUnit-module__continueBtn_kbfy0W__",
  "finishedOverlay": "WatchUnit-module__finishedOverlay_kbfy0W__",
  "finishedTextContainer": "WatchUnit-module__finishedTextContainer_kbfy0W__",
  "hidden": "WatchUnit-module__hidden_kbfy0W__",
  "initialLoadingSpinner": "WatchUnit-module__initialLoadingSpinner_kbfy0W__",
  "instructionsContainer": "WatchUnit-module__instructionsContainer_kbfy0W__",
  "muteBtn": "WatchUnit-module__muteBtn_kbfy0W__",
  "nextCourseText": "WatchUnit-module__nextCourseText_kbfy0W__",
  "overlay": "WatchUnit-module__overlay_kbfy0W__",
  "playBtn": "WatchUnit-module__playBtn_kbfy0W__",
  "playIcon": "WatchUnit-module__playIcon_kbfy0W__",
  "replayBtn": "WatchUnit-module__replayBtn_kbfy0W__",
  "rightContainer": "WatchUnit-module__rightContainer_kbfy0W__",
  "smallPauseBtn": "WatchUnit-module__smallPauseBtn_kbfy0W__",
  "smallPlayBtn": "WatchUnit-module__smallPlayBtn_kbfy0W__",
  "soundBtn": "WatchUnit-module__soundBtn_kbfy0W__",
  "subtopicNavigationContainer": "WatchUnit-module__subtopicNavigationContainer_kbfy0W__",
  "upNextText": "WatchUnit-module__upNextText_kbfy0W__",
  "video": "WatchUnit-module__video_kbfy0W__",
  "videoContainer": "WatchUnit-module__videoContainer_kbfy0W__",
  "videoControls": "WatchUnit-module__videoControls_kbfy0W__",
  "videoCurrentTimeContainer": "WatchUnit-module__videoCurrentTimeContainer_kbfy0W__",
  "videoCurrentTimeIcon": "WatchUnit-module__videoCurrentTimeIcon_kbfy0W__",
  "videoEndTimeContainer": "WatchUnit-module__videoEndTimeContainer_kbfy0W__",
  "videoLoading": "WatchUnit-module__videoLoading_kbfy0W__",
  "videoTimeContainer": "WatchUnit-module__videoTimeContainer_kbfy0W__",
  "videoTimeText": "WatchUnit-module__videoTimeText_kbfy0W__",
  "videoTimeline": "WatchUnit-module__videoTimeline_kbfy0W__",
  "videoTimelineContainer": "WatchUnit-module__videoTimelineContainer_kbfy0W__",
  "waitingSpinner": "WatchUnit-module__waitingSpinner_kbfy0W__",
  "waitingSpinnerContainer": "WatchUnit-module__waitingSpinnerContainer_kbfy0W__"
};
      