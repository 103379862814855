import React from 'react';
import { PauseOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
const { Text } = Typography;

import styles from '../../stylesheets/RecordingControlNotStarted.module.css';

const RecordingControlNotStarted = () => {
  return (
    <div className={styles.container}>
      <div className={styles.deleteContainer}>
        <DeleteOutlined className={styles.deleteButton} />
      </div>
      <div className={styles.verticalDivider} />
      <div className={styles.timeElapsedContainer}>
        <Text className={styles.timeElapsedText}>
          0:00
        </Text>
      </div>
      <div className={styles.pauseContainer}>
        <PauseOutlined className={styles.pauseButton} />
      </div>
    </div>
  );
};

export default RecordingControlNotStarted;