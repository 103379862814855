import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/GrantDevicePermissionsModal.module.css";

export default {
  "audioIcon": "GrantDevicePermissionsModal-module__audioIcon_hM8VlG__",
  "body": "GrantDevicePermissionsModal-module__body_hM8VlG__",
  "cancelButton": "GrantDevicePermissionsModal-module__cancelButton_hM8VlG__",
  "clickAccept": "GrantDevicePermissionsModal-module__clickAccept_hM8VlG__",
  "content": "GrantDevicePermissionsModal-module__content_hM8VlG__",
  "footer": "GrantDevicePermissionsModal-module__footer_hM8VlG__",
  "grantPermissions": "GrantDevicePermissionsModal-module__grantPermissions_hM8VlG__",
  "iconContainer": "GrantDevicePermissionsModal-module__iconContainer_hM8VlG__",
  "okButton": "GrantDevicePermissionsModal-module__okButton_hM8VlG__",
  "okButtonHidden": "GrantDevicePermissionsModal-module__okButtonHidden_hM8VlG__",
  "title": "GrantDevicePermissionsModal-module__title_hM8VlG__",
  "title1": "GrantDevicePermissionsModal-module__title1_hM8VlG__",
  "title2": "GrantDevicePermissionsModal-module__title2_hM8VlG__",
  "titleContainer": "GrantDevicePermissionsModal-module__titleContainer_hM8VlG__",
  "videoCameraIcon": "GrantDevicePermissionsModal-module__videoCameraIcon_hM8VlG__"
};
      