import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { ConfigProvider, Menu, Layout, Typography, Button } from 'antd';
import { 
  CheckCircleFilled, 
  LeftOutlined,
} from '@ant-design/icons';
import axios from 'axios';

const { Text } = Typography;
const { Sider } = Layout;

import { UnitStatuses } from '../lib/constants';
import { unitPath } from '../lib/urls';

import styles from '../../stylesheets/Unit.module.css';

const Unit = () => {
  const { subtopicToken, unitToken } = useParams();

  const navigate = useNavigate();

  const [subtopic, setSubtopic] = useState(null);
  const [adjacentUnits, setAdjacentUnits] = useState({
    previousUnit: null,
    nextUnit: null,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchSubtopic() {
      const response = await axios.get(`/api/subtopic/fetch?token=${subtopicToken}`);
      setSubtopic(response.data.subtopic);
      setIsLoading(false);
    }

    fetchSubtopic();
  }, []);

  useEffect(() => {
    async function fetchAdjacentUnits() {
      const response = await axios.get(`/api/subtopic/fetch-adjacent-units?unit_token=${unitToken}`);
      setAdjacentUnits({
        previousUnit: response.data.previous_unit,
        nextUnit: response.data.next_unit,
      });
    }

    fetchAdjacentUnits()
  }, [unitToken]);

  const navItems = () => {
    return subtopic.units.map((unit) => {
      let icon;
      if (unit.status == UnitStatuses.COMPLETE) {
        icon = <CheckCircleFilled style={{ color: '#2E4A8B' }} />;
      } else {
        icon = <div className={styles.waitingIcon} />;
      }

      return(
        {
          key: unit.token,
          label: unit.title,
          icon: icon,
          path: unitPath(subtopicToken, unit.token, unit.unit_type)
        }
      );
    });
  }

  const handleSeeAllModulesClick = () => {
    navigate(`/?topicToken=${subtopic.topic_token}`);
  }

  const handleUnitSelected = (item) => {
    navigate(item.item.props.path);
  }

  if (isLoading) {
    return <div/>;
  }

  const unit = subtopic.units.find((unit) => unit.token == unitToken);

  return (
    <Layout className={styles.container}>
      <Sider 
        className={styles.sideNavContainer}
        style={{ backgroundColor: '#FFFAEC', position: 'sticky', top: '0' }}
        width={300}
      >
        <div className={styles.allSubtopicsLinkContainer}>
          <Button 
            type="link" 
            className={styles.allSubtopicsBtn}
            icon={<LeftOutlined />}
            onClick={handleSeeAllModulesClick}
          >
            See all modules
          </Button>
        </div>
        <div className={styles.subtopicTitleContainer}>
          <Text className={styles.subtopicTitle}>
            {subtopic.title}
          </Text>
        </div>
        <ConfigProvider
          theme={{
            "components": {
              "Menu": {
                "itemBg": "#FFFAEC",
                "colorText": "#000000",
                "iconSize": 18,
              }
            }
          }}
        >
          <Menu
            className={styles.sideNav}
            selectedKeys={[unitToken]}
            mode="inline"
            items={navItems()}
            onSelect={handleUnitSelected}
          />
        </ConfigProvider>
      </Sider>
      <div className={styles.mainContent}>
        <Outlet 
          context={{
            unit: unit,
            previousUnit: adjacentUnits.previousUnit,
            nextUnit: adjacentUnits.nextUnit,
          }}
          key={unitToken}
        />
      </div>
    </Layout>
  );
};

export default Unit;