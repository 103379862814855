import axios from 'axios';

const CHUNK_MILLISECONDS = 20000;
const CHUNK_OVERLAP = 2000;

class PresentationRecorder {
  constructor(stream, audioMessageId) {
    this.mediaRecorder = new MediaRecorder(stream, { audioBitsPerSecond: 128000 });
    this.buffer = [];
    this.startTimeoutId = null;
    this.stopTimeoutId = null;
    this.audioMessageId = audioMessageId;
  }

  startRecorder = () => {
    this.mediaRecorder.ondataavailable = (event) => { this.buffer.push(event.data) };
    this.mediaRecorder.onstop = () => { this.uploadAndClearBuffer() };

    this.mediaRecorder.start();
    this.stopTimeoutId = setTimeout(() => {
        this.mediaRecorder.stop();
    }, CHUNK_MILLISECONDS);

    this.startTimeoutId = setTimeout(() => {
        this.startRecorder();
    }, (CHUNK_MILLISECONDS - CHUNK_OVERLAP) * 2)
  }

  startAfterChunkDelay = () => {
    this.startTimeoutId = setTimeout (() => {
      this.startRecorder();
    }, CHUNK_MILLISECONDS - CHUNK_OVERLAP);
  }

  bufferSize = () => {
    return this.buffer.reduce((acc, chunk) => acc + chunk.size, 0);
  }

  uploadAndClearBuffer = async () => {
    const audioChunk = new Blob(this.buffer, { type: 'audio/webm' });

    if (this.bufferSize() === 0) {
      return;
    }

    console.log('Uploading buffer of size: ' + this.bufferSize());
    const audioFormData = this.audioFormData(audioChunk);
    
    this.clearBuffer();
    return await axios.post('/api/audio-message-chunk/upload', audioFormData);
  }

  uploadAsFinalAndClearBuffer = async () => {
    const audioChunk = new Blob(this.buffer, { type: 'audio/webm' });
    console.log('Uploading buffer of size: ' + this.bufferSize());
    const audioFormData = this.audioFormData(audioChunk);
    
    this.clearBuffer();
    return await axios.post('/api/audio-message-chunk/upload-final', audioFormData);
  }

  stopRecorder = () => {
    clearTimeout(this.startTimeoutId);
    clearTimeout(this.stopTimeoutId);

    this.mediaRecorder.stop();
  }

  endRecording = () => {
    return new Promise((resolve) => {
      if (this.mediaRecorder.state !== 'recording') {
        const audioBlob = new Blob([], { type: 'audio/webm' });
        this.stopRecorder();
        resolve(audioBlob);
      } else {
        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.buffer.push(event.data);
          }
  
          const audioBlob = new Blob(this.buffer, { type: 'audio/webm' });
          resolve(audioBlob);
        };
  
        this.stopRecorder();
      }
    });
  }

  audioFormData = (audioChunk) => {
    const formData = new FormData();
    formData.append('audio_chunk', audioChunk, `audio_chunk_${Date.now()}.webm`);
    formData.append('audio_message_id', this.audioMessageId);

    return formData;
  }

  clearBuffer = () => {
    this.buffer = [];
  }
}

export default PresentationRecorder;