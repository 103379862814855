import React from 'react';
import { Modal, Typography } from 'antd';
import { AudioOutlined, VideoCameraOutlined } from '@ant-design/icons';
const { Title } = Typography;

import styles from '../../stylesheets/GrantDevicePermissionsModal.module.css';
import { DevicePermissionsModal } from '../lib/constants';

const GrantDevicePermissionsModal = (props) => {
  const isGrantPermissionsModal = () => {
    return props.devicePermissionsModal === DevicePermissionsModal.GRANT_PERMISSIONS;
  }

  const isClickAcceptModal = () => {
    return props.devicePermissionsModal === DevicePermissionsModal.CLICK_ACCEPT;
  }

  const isOpen = () => {
    return isGrantPermissionsModal() || isClickAcceptModal();
  }

  const okButtonProps = () => {
    if (isGrantPermissionsModal()) {
      return { className: styles.okButton };
    } else {
      return { className: styles.okButtonHidden };
    }
  }

  const handleClose = () => {
    if (isGrantPermissionsModal()) {
      props.handleClose();
    }
  }

  const modalContent = () => {
    if (isGrantPermissionsModal()) {
      return(
        <div className={styles.grantPermissions}>
          <div className={styles.iconContainer}>
            <AudioOutlined 
              className={styles.audioIcon}
            />
            <VideoCameraOutlined
              className={styles.videoCameraIcon}
            />
          </div>
  
          <Title 
            level={5}
            className={styles.title}
          >
            Allow microphone & camera access
          </Title>
          <p className={styles.body}>
            To begin your practice session, we need your permission to use your microphone and camera.
          </p>
        </div>
      );
    } else {
      return(
        <div className={styles.clickAccept}>
          <div className={styles.titleContainer}>
            <span className={styles.title1}>
              Click&nbsp;
            </span>
            <span className={styles.title2}>
              Allow
            </span>
          </div>
          <p>
            Your microphone and camera will only be used during your practice sessions.
          </p>
        </div>
      )
    }
  }

  return(
    <Modal
      open={isOpen()}
      onOk={props.handleOk}
      closable={isGrantPermissionsModal()}
      onCancel={handleClose}
      cancelButtonProps={{ className: styles.cancelButton }}
      okText="Allow microphone & camera"
      okButtonProps={okButtonProps()}
      wrapClassName={styles.modal}
      classNames={{
        content: styles.content,
        footer: styles.footer,
      }}
    >
      {modalContent()}
  </Modal>
  )
};

export default GrantDevicePermissionsModal;