import React from 'react';
import { Outlet, useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { Layout, Button, Row, Col } from 'antd';
import { 
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

const { Content } = Layout;

import { unitPath } from '../lib/urls';
import styles from '../../stylesheets/PracticeScenario.module.css';
import '../../stylesheets/Instructions.css';

const PracticeScenario = () => {
  const { unit, previousUnit, nextUnit } = useOutletContext();
  const { subtopicToken } = useParams();
  const navigate = useNavigate();

  const handlePreviousUnitClick = () => {
    navigate(unitPath(subtopicToken, previousUnit.token, previousUnit.unit_type));
  }

  const handleNextUnitClick = () => {
    navigate(unitPath(subtopicToken, nextUnit.token, nextUnit.unit_type));
  }

  return (
    <Content>
      <Row>
        <Col 
          span={18}
          style={{ paddingLeft: '32px', paddingRight: '32px' }}
        >
          <Outlet 
            context={{
              practiceScenario: unit,
              previousUnit: previousUnit,
              nextUnit: nextUnit,
            }}
          />
        </Col>
        <Col
          span={6}
          style={{ paddingLeft: '32px', paddingRight: '32px' }}
        >
          <div className={styles.rightContainer}>
            <div className={styles.subtopicNavigationContainer}>
              <Button 
                type="link" 
                icon={<LeftOutlined />}
                onClick={handlePreviousUnitClick}
                disabled={previousUnit === null}
              >
                Previous
              </Button>
              <Button 
                type="link"
                icon={<RightOutlined />}
                iconPosition="end"
                onClick={handleNextUnitClick}
                disabled={nextUnit === null}
              >
                Next
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
};

export default PracticeScenario;