import React, { useState, useRef } from 'react';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { 
  Spin, 
  Typography,
  Layout,
  Col,
} from 'antd';
const { Title } = Typography;
const { Content } = Layout;

import Controls from './Controls';
import { VideoPlayerStates } from '../lib/constants';
import { practiceInstructionsPath } from '../lib/urls';
import styles from '../../stylesheets/PracticeIntroVideo.module.css';

const PracticeIntroVideo = () => {
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const { subtopicToken, unitToken } = useParams();
  const { practiceScenario } = useOutletContext();

  const [playerState, setPlayerState] = useState(VideoPlayerStates.NOT_PLAYING);
  const [isVideoLoading, setVideoLoading] = useState(true);

  const handlePause = () => {
    videoRef.current.pause();
    setPlayerState(VideoPlayerStates.NOT_PLAYING);
  }

  const handlePlay = () => {
    videoRef.current.play();
    setPlayerState(VideoPlayerStates.PLAYING);
  }

  const handleReplayVideo = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
    setPlayerState(VideoPlayerStates.PLAYING);
  }

  const handleVideoEnded = () => {
    setPlayerState(VideoPlayerStates.FINISHED);
  }

  const handleFinish = () => {
    navigate(practiceInstructionsPath(subtopicToken, unitToken));
  }

  const handleVideoLoaded = () => {
    setVideoLoading(false);
  }

  const videoLoadingClassName = () => {
    if (isVideoLoading) {
      return styles.videoLoading;
    } else {
      return styles.hidden;
    }
  }

  const videoContainerClassName = () => {
    if (isVideoLoading) {
      return styles.hidden;
    } else {
      return styles.video;
    }
  }

  return(
    <Content>
      <Col span={18}>
        <Title level={3} style={{ marginBottom: '15px' }}>
          {practiceScenario.title}
        </Title>
        <div className={styles.videoContainer}>
          <div className={videoLoadingClassName()}>
            <Spin 
              indicator={<LoadingOutlined className={styles.loading} spin />} 
            />
          </div>
          <video
            ref={videoRef}
            controls={false}
            className={videoContainerClassName()}
            onLoadedData={handleVideoLoaded}
            onEnded={handleVideoEnded}
          >
            <source src={practiceScenario.primary_video_signed_url} type="video/mp4" />
          </video>
          <Controls 
            playerState={playerState}
            handlePlay={handlePlay}
            handlePause={handlePause}
            handleReplayAudio={handleReplayVideo}
            handleFinish={handleFinish}
            playButtonLabel='Play intro'
            pauseButtonLabel='Pause'
            replayButtonLabel='Replay'
          />
        </div>
      </Col>
    </Content>
  );
};

export default PracticeIntroVideo;