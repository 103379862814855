import PresentationRecorder from './PresentationRecorder';

class PresentationRecorderCoordinator {
  constructor(audioMessageId, deviceManager) {
    this.audioMessageId = audioMessageId;
    this.deviceManager = deviceManager;
    this.primaryRecorder = null;
    this.secondaryRecorder = null;
  }

  beginRecording = async () => {
    this.primaryRecorder = new PresentationRecorder(await this.stream(), this.audioMessageId);
    this.primaryRecorder.startRecorder();

    this.secondaryRecorder = new PresentationRecorder(await this.stream(), this.audioMessageId);
    this.secondaryRecorder.startAfterChunkDelay(this.stream);
  }

  endRecording = async () => {
    const audioBlobs = await Promise.all([
      this.primaryRecorder.endRecording(), 
      this.secondaryRecorder.endRecording()
    ]);

    const primaryBlob = audioBlobs[0];
    const secondaryBlob = audioBlobs[1];

    let response;

    if (primaryBlob.size > secondaryBlob.size) {
      response = await this.primaryRecorder.uploadAsFinalAndClearBuffer();
    } else {
      response = await this.secondaryRecorder.uploadAsFinalAndClearBuffer();
    }

    this.cachedStream.getTracks().forEach( track => track.stop() );
    this.cachedStream = null;

    return response;
  }

  pauseRecording = async () => {
    const audioBlobs = await Promise.all([
      this.primaryRecorder.endRecording(), 
      this.secondaryRecorder.endRecording()
    ]);

    const primaryBlob = audioBlobs[0];
    const secondaryBlob = audioBlobs[1];

    if (primaryBlob.size > secondaryBlob.size) {
      this.primaryRecorder.uploadAndClearBuffer();
    } else {
      this.secondaryRecorder.uploadAndClearBuffer();
    }
  }

  endRecordingWhilePaused = () => {
    this.cachedStream.getTracks().forEach( track => track.stop() );
    this.cachedStream = null;
  }

  stream = async () => {
    if (!this.cachedStream) {
      this.cachedStream = await this.deviceManager.getUserMedia({ audio: true });
    }

    return this.cachedStream;
  }
}

export default PresentationRecorderCoordinator;