import React, { createRef } from 'react';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { 
    Typography, 
    Button, 
    Modal,
    Layout,
    Col,
} from 'antd';
import { LeftOutlined, RightOutlined, AudioFilled } from '@ant-design/icons';
const { Text, Title } = Typography;
const { Content } = Layout;

import RecordingControlNotStarted from './RecordingControlNotStarted';
import GrantDevicePermissionsModal from './GrantDevicePermissionsModal';
import BrowserPermissionsDeniedModal from './BrowserPermissionsDeniedModal';
import OSPermissionsDeniedModal from './OSPermissionsDeniedModal';
import RecordingControlRecording from './RecordingControlRecording';
import DevicePermissions from '../lib/DevicePermissions';
import DeviceManager from '../lib/DeviceManager';
import PresentationRecorderCoordinator from '../lib/PresentationRecorderCoordinator';
import { RecordingStates, DevicePermissionsModal, DevicePermissionsState } from '../lib/constants';

import styles from '../../stylesheets/PresentationPractice.module.css';

class PresentationPractice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recordingState: RecordingStates.NOT_STARTED,
            countdownSecondsRemaining: 3,
            recordingElapsedSeconds: 0,
            activeSlideNumber: 1,
            showRestartModal: false,
            isDeletingPracticeAttempt: false,
            devicePermissionsModal: DevicePermissionsModal.NONE,
            showVideoFeed: false,
        };
        this.presentationRecorderCoordination = null;
        this.audioMessageId = null;
        this.deviceManager = new DeviceManager();
        this.devicePermissions = new DevicePermissions(this.deviceManager);
        this.videoRef = createRef();
    }

    componentDidMount = async () => {
        const permissionsState = await this.devicePermissions.permissionStateWithoutRequest();
        if (permissionsState === DevicePermissionsState.GRANTED) {
            this.startVideoFeed();
        }
    }

    componentWillUnmount() {
        this.deviceManager.stopStreams();
    }

    navigateToPreviousSlide = () => {
        this.navigateSlide(-1);
    }

    navigateToNextSlide = () => {
        this.navigateSlide(1);
    }

    navigateSlide = ((increment) => {
        this.setState({
            activeSlideNumber: this.state.activeSlideNumber + increment,
        });
    });

    createAudioMessage = async() => {
        const response = await axios.post('/api/audio-message/create', {
            practice_attempt_token: this.props.params.practiceAttemptToken,
        });

        this.audioMessageId = response.data.audio_message.id;
    }

    decrementCountdown = () => {
        const countdownSecondsRemaining = this.state.countdownSecondsRemaining;

        if (countdownSecondsRemaining > 1) {
            this.setState({
                countdownSecondsRemaining: this.state.countdownSecondsRemaining - 1,
            })
        } else {
            clearInterval(this.countdownIntervalId);
            this.beginPracticeSession();
        }
    }

    showNavigateForwardSlideButton = () => {
        const slides = this.props.practiceScenario.practice_slides;

        return this.state.activeSlideNumber < slides.length &&
            this.state.recordingState != RecordingStates.NOT_STARTED &&
            this.state.recordingState != RecordingStates.COUNTING_DOWN
    }

    showNavigateBackSlideButton = () => {
        return this.state.activeSlideNumber !== 1 &&
            this.state.recordingState != RecordingStates.NOT_STARTED &&
            this.state.recordingState != RecordingStates.COUNTING_DOWN
    }

    handleBeginRecording = async () => {
        const permissionState = await this.devicePermissions.permissionStateWithoutRequest();

        switch (permissionState) {
        case DevicePermissionsState.ASK:
            this.setState({
                devicePermissionsModal: DevicePermissionsModal.GRANT_PERMISSIONS,
            });
            break;
        case DevicePermissionsState.BROWSER_DENIED:
            this.setState({
                devicePermissionsModal: DevicePermissionsModal.BROWSER_PERMISSIONS_DENIED,
            });
            break;
        case DevicePermissionsState.OS_DENIED:
            this.setState({
                devicePermissionsModal: DevicePermissionsModal.OS_PERMISSIONS_DENIED,
            });
            break;
        case DevicePermissionsState.GRANTED:
            this.beginCountdown();
            break;
        }
    }

    beginCountdown = () => {
        this.setState({
            recordingState: RecordingStates.COUNTING_DOWN,
        });
        this.countdownIntervalId = setInterval(() => {
            this.decrementCountdown();
        }, 1000);
    }

    beginPracticeSession = async () => {
        await this.createAudioMessage();
        this.presentationRecorderCoordinator = new PresentationRecorderCoordinator(this.audioMessageId, this.deviceManager);
        this.presentationRecorderCoordinator.beginRecording();

        this.setState({ recordingState: RecordingStates.RECORDING });
        this.recordingSecondsIntervalId = setInterval(this.incrementRecordingElapsedSeconds, 1000);
    }

    handleUnpauseSession = async () => {
        this.presentationRecorderCoordinator.beginRecording();
        this.setState({ recordingState: RecordingStates.RECORDING });
        this.recordingSecondsIntervalId = setInterval(this.incrementRecordingElapsedSeconds, 1000);
    }

    handlePauseSession = () => {
        this.presentationRecorderCoordinator.pauseRecording();
        this.setState({ recordingState: RecordingStates.PAUSED });
        clearInterval(this.recordingSecondsIntervalId);
    }

    handleSubmitWhileRecording = async () => {
        this.setState({ recordingState: RecordingStates.SUBMITTING });
        clearInterval(this.recordingSecondsIntervalId);
        const response = await this.presentationRecorderCoordinator.endRecording();

        const subtopicToken = this.props.params.subtopicToken
        const practiceScenarioToken = this.props.params.unitToken
        const feedbackToken = response.data.feedback_token;
        this.props.navigate(`/subtopic/${subtopicToken}/practice/${practiceScenarioToken}/session-completed/${feedbackToken}`);
    }

    handleSubmitWhilePaused = async () => {
        this.setState({ recordingState: RecordingStates.SUBMITTING });
        clearInterval(this.recordingSecondsIntervalId);
        const response = await axios.post('/api/audio-message/end-recording', {
            audio_message_id: this.audioMessageId,
        });

        this.presentationRecorderCoordinator.endRecordingWhilePaused();

        clearInterval(this.recordingSecondsIntervalId);

        const subtopicToken = this.props.params.subtopicToken
        const practiceScenarioToken = this.props.params.unitToken
        const feedbackToken = response.data.feedback_token;
        this.props.navigate(`/subtopic/${subtopicToken}/practice/${practiceScenarioToken}/session-completed/${feedbackToken}`);
    }

    incrementRecordingElapsedSeconds = () => {
        this.setState((prevState) => ({
          recordingElapsedSeconds: prevState.recordingElapsedSeconds + 1,
        }));
      };

    formatElapsedTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
    
        const formattedSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;
    
        return `${minutes}:${formattedSeconds}`;
    }

    controlPanel() {
        const recordingState = this.state.recordingState;
        if (recordingState == RecordingStates.NOT_STARTED || recordingState == RecordingStates.COUNTING_DOWN) {
            return <RecordingControlNotStarted />;
        } else {
            return(
                <RecordingControlRecording 
                    elapsedTime={this.formatElapsedTime(this.state.recordingElapsedSeconds)} 
                    recordingState={this.state.recordingState}
                    handlePauseSession={this.handlePauseSession}
                    handleUnpauseSession={this.handleUnpauseSession}
                    handleShowDeleteModal={this.handleShowDeleteModal}
                    handleSubmitWhileRecording={this.handleSubmitWhileRecording}
                    handleSubmitWhilePaused={this.handleSubmitWhilePaused}
                />
            )
        }
    }

    handleShowDeleteModal = () => {
        this.setState({ showRestartModal: true });
    }

    handleResumeSession = () => {
        this.setState({ showRestartModal: false });
    }

    handleDeleteSession = async () => {
        this.setState({ isDeletingPracticeAttempt: true });
        await this.presentationRecorderCoordinator.endRecording();
        axios.post('/api/practice-attempt/cancel', {
            practice_attempt_token: this.props.params.practiceAttemptToken,
        });

        const subtopicToken = this.props.params.subtopicToken
        const practiceScenarioToken = this.props.params.unitToken

        this.props.navigate(`/subtopic/${subtopicToken}/practice/${practiceScenarioToken}`);
    }

    handleGrantDevicePermissions = async () => {
        this.setState({ devicePermissionsModal: DevicePermissionsModal.CLICK_ACCEPT });

        const permissionState = await this.devicePermissions.permissionStateWithRequest();
        switch (permissionState) {
        case DevicePermissionsState.BROWSER_DENIED:
            this.setState({
                devicePermissionsModal: DevicePermissionsModal.BROWSER_PERMISSIONS_DENIED,
            });
            break;
        case DevicePermissionsState.OS_DENIED:
            this.setState({
                devicePermissionsModal: DevicePermissionsModal.OS_PERMISSIONS_DENIED,
            });
            break;
        case DevicePermissionsState.GRANTED:
            this.setState({ devicePermissionsModal: DevicePermissionsModal.NONE });
            this.startVideoFeed();
            break;
        }
    }

    startVideoFeed = async () => {
        const stream = await this.deviceManager.getUserMedia({ video: true });
        debugger;
        this.videoRef.current.srcObject = stream;
        this.setState({ showVideoFeed: true });
    }

    handleClosePermissionsModal = () => {
        this.setState({ devicePermissionsModal: DevicePermissionsModal.NONE });
    }

    slideClassName = (slide) => {
        if (slide.order === this.state.activeSlideNumber) {
            return styles.slideVisible;
        } else {
            return styles.slideHidden;
        }
    }

    slides = () => {
        return this.props.practiceScenario.practice_slides.map((slide) => { 
            return(
                <img 
                    width="100%" 
                    src={slide.url}
                    className={this.slideClassName(slide)}
                    key={slide.id}
                />
            );
        });
    }

    videoStreamClassName = () => {
        if (this.state.showVideoFeed) {
            return styles.videoStream;
        } else {
            return styles.videoStreamHidden;
        }
    }

    render() {
        const recordingState = this.state.recordingState;
        const isDeletingPracticeAttempt = this.state.isDeletingPracticeAttempt;
    
        return(
            <Content>
                <Col span={18}>
                    <Title level={3} style={{ marginBottom: '15px' }}>
                        {this.props.practiceScenario.title}
                    </Title>
                    <div className={styles.container}>
                        <div className={styles.slideContainer}>
                        { (recordingState == RecordingStates.NOT_STARTED || recordingState == RecordingStates.COUNTING_DOWN) && 
                            <div className={styles.overlay} />}
                        {
                            this.showNavigateBackSlideButton() &&
                            <Button 
                                shape="circle" 
                                size="large"
                                icon={<LeftOutlined />} 
                                className={`${styles.slideButton} ${styles.slideButtonLeft}`}
                                onClick={this.navigateToPreviousSlide}
                            />
                        }
                            <div className={styles.videoRecorderContainer}>
                                <div className={styles.videoStreamContainer}>
                                    <video 
                                        ref={this.videoRef} 
                                        autoPlay 
                                        playsInline 
                                        muted 
                                        className={this.videoStreamClassName()}
                                        disablePictureInPicture
                                    />
                                </div>
                            </div>
                            {this.slides()}
                            { 
                                recordingState == RecordingStates.NOT_STARTED && 
                                    <div 
                                        className={styles.beginRecordingBtn}
                                        onClick={this.handleBeginRecording}
                                    >
                                        <AudioFilled className={styles.audioIcon} />
                                        <Text className={styles.audioText}>Begin Recording</Text>
                                    </div>
                            }
                                                    { 
                                recordingState == RecordingStates.COUNTING_DOWN && 
                                    <div className={styles.countDownContainer}>
                                        <Text className={styles.countDownText}>
                                            {this.state.countdownSecondsRemaining}
                                        </Text>
                                    </div>
                            }
                            {
                                this.showNavigateForwardSlideButton() &&
                                <Button 
                                    shape="circle" 
                                    size="large"
                                    icon={<RightOutlined />} 
                                    className={`${styles.slideButton} ${styles.slideButtonRight}`}
                                    onClick={this.navigateToNextSlide}
                                />
                            }
                        </div>
                        {this.controlPanel()}
                    </div>
                    <Modal 
                        title="Are you sure you want to delete your practice attempt?" 
                        open={this.state.showRestartModal} 
                        onOk={this.handleDeleteSession}
                        onCancel={this.handleResumeSession}
                        cancelText="Resume"
                        okText={isDeletingPracticeAttempt ? "Deleting" : "Delete practice attempt"}
                        okButtonProps={{
                            danger: true, 
                            loading: isDeletingPracticeAttempt
                        }}
                    >
                        <p>Your current practice session will be lost.</p>
                    </Modal>
                    <GrantDevicePermissionsModal
                        handleOk={this.handleGrantDevicePermissions}
                        handleClose={this.handleClosePermissionsModal}
                        devicePermissionsModal={this.state.devicePermissionsModal}
                    />
                    <BrowserPermissionsDeniedModal
                        isOpen={this.state.devicePermissionsModal === DevicePermissionsModal.BROWSER_PERMISSIONS_DENIED}
                        handleClose={this.handleClosePermissionsModal}
                    />
                    <OSPermissionsDeniedModal
                        isOpen={this.state.devicePermissionsModal === DevicePermissionsModal.OS_PERMISSIONS_DENIED}
                        handleClose={this.handleClosePermissionsModal}
                    />
                </Col>
            </Content>
        )
    }
}

const PresentationPracticeWrapper = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const outletContext = useOutletContext();

    return(
        <PresentationPractice 
            {...props} 
            params={params} 
            navigate={navigate}
            {...outletContext}
        />
    );
        
};

export default PresentationPracticeWrapper;