import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Form, Input, Button, Alert } from 'antd';
import axios from 'axios';
const { Title } = Typography;

import { LoginApiStatusCodes } from '../lib/constants';
import styles from '../../stylesheets/Login.module.css';

const Login = () => {
  const navigate = useNavigate();
  const emailInputRef = useRef(null);
  const [hasError, setError] = useState(false);
  const [isLoggingIn, setLoggingIn] = useState(false);

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);


  const handleLogin = async (items) => {
    setError(false);
    setLoggingIn(true);
    const response = await axios.post('/api/login', items);

    if (response.data.status == LoginApiStatusCodes.SUCCESS) {
      setLoggingIn(false);
      navigate('/');
    } else {
      setError(true);
      setLoggingIn(false);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        {
          hasError &&
            <Alert 
              type='error'
              message='Invalid email or password.'
              className={styles.errorAlert}
            />
        }
        <div className={styles.instructLogoContainer}>
          <img
            src='https://development-instruct.s3.us-east-2.amazonaws.com/instruct-logo.jpg'
            className={styles.instructLogo}
          />
        </div>
        <div className={styles.welcomeMsgContainer}>
          <Title level={2} className={styles.welcomeMsg}>
            Welcome to Instruct
          </Title>
        </div>

        <Form 
          layout='vertical' 
          className={styles.loginForm}
          onFinish={handleLogin}
          requiredMark={false}
        >
          <Form.Item
            label='Email'
            name='email'
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input ref={emailInputRef} />
          </Form.Item>

          <Form.Item
            label='Password'
            name='password'
            rules={[
              { 
                required: true,
                message: '',
              }
            ]}
            className={styles.passwordFormItem}
          >
            <Input.Password />
          </Form.Item>
          <Button 
            type='link' 
            className={styles.forgotPasswordBtn}
            onClick={() => { navigate('/forgot-password') }}
          >
            Forgot password?
          </Button>

          <Form.Item>
            <Button 
              size='large'
              htmlType='submit'
              type='primary'
              className={styles.loginBtn}
              loading={isLoggingIn}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;