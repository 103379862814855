import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/Unit.module.css";

export default {
  "allSubtopicsBtn": "Unit-module__allSubtopicsBtn_BdVSga__",
  "allSubtopicsLinkContainer": "Unit-module__allSubtopicsLinkContainer_BdVSga__",
  "container": "Unit-module__container_BdVSga__",
  "mainContent": "Unit-module__mainContent_BdVSga__",
  "sideNav": "Unit-module__sideNav_BdVSga__",
  "sideNavContainer": "Unit-module__sideNavContainer_BdVSga__",
  "subtopicTitle": "Unit-module__subtopicTitle_BdVSga__",
  "subtopicTitleContainer": "Unit-module__subtopicTitleContainer_BdVSga__",
  "unitTitle": "Unit-module__unitTitle_BdVSga__",
  "waitingIcon": "Unit-module__waitingIcon_BdVSga__"
};
      