import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/Login.module.css";

export default {
  "container": "Login-module__container_ed7Z1G__",
  "errorAlert": "Login-module__errorAlert_ed7Z1G__",
  "forgotPasswordBtn": "Login-module__forgotPasswordBtn_ed7Z1G__",
  "instructLogo": "Login-module__instructLogo_ed7Z1G__",
  "instructLogoContainer": "Login-module__instructLogoContainer_ed7Z1G__",
  "loginBtn": "Login-module__loginBtn_ed7Z1G__",
  "loginContainer": "Login-module__loginContainer_ed7Z1G__",
  "loginForm": "Login-module__loginForm_ed7Z1G__",
  "passwordFormItem": "Login-module__passwordFormItem_ed7Z1G__",
  "welcomeMsg": "Login-module__welcomeMsg_ed7Z1G__",
  "welcomeMsgContainer": "Login-module__welcomeMsgContainer_ed7Z1G__"
};
      