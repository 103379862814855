import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/Overview.module.css";

export default {
  "container": "Overview-module__container_Cq2dHa__",
  "detailsContainer": "Overview-module__detailsContainer_Cq2dHa__",
  "mainContentContainer": "Overview-module__mainContentContainer_Cq2dHa__",
  "onlyOneDetailsContainer": "Overview-module__onlyOneDetailsContainer_Cq2dHa__",
  "title": "Overview-module__title_Cq2dHa__",
  "titleContainer": "Overview-module__titleContainer_Cq2dHa__",
  "topicCardsContainer": "Overview-module__topicCardsContainer_Cq2dHa__"
};
      