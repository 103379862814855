import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/NewPresentationPracticeAttempt.module.css";

export default {
  "hidden": "NewPresentationPracticeAttempt-module__hidden_f0ozJW__",
  "loadingSpinner": "NewPresentationPracticeAttempt-module__loadingSpinner_f0ozJW__",
  "loadingSpinnerContainer": "NewPresentationPracticeAttempt-module__loadingSpinnerContainer_f0ozJW__",
  "noDisplay": "NewPresentationPracticeAttempt-module__noDisplay_f0ozJW__",
  "overlay": "NewPresentationPracticeAttempt-module__overlay_f0ozJW__",
  "slideContainer": "NewPresentationPracticeAttempt-module__slideContainer_f0ozJW__",
  "slideImage": "NewPresentationPracticeAttempt-module__slideImage_f0ozJW__",
  "yourTurnContainer": "NewPresentationPracticeAttempt-module__yourTurnContainer_f0ozJW__",
  "yourTurnDescription": "NewPresentationPracticeAttempt-module__yourTurnDescription_f0ozJW__",
  "yourTurnTitle": "NewPresentationPracticeAttempt-module__yourTurnTitle_f0ozJW__"
};
      