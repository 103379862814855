class DeviceManager {
  constructor() {
    this.streams = [];
  }

  async getUserMedia(constraints) {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    this.streams.push(stream);
    return stream;
  }

  async permission(permissionName) {
    return await navigator.permissions.query({ name: permissionName });
  }

  stopStreams() {
    this.streams.forEach((stream) => {
      stream.getTracks().forEach((track) => track.stop())
    });
  }
}

export default DeviceManager;