import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/ResetPassword.module.css";

export default {
  "confirmPasswordFormItem": "ResetPassword-module__confirmPasswordFormItem_-0_6aa__",
  "container": "ResetPassword-module__container_-0_6aa__",
  "errorAlert": "ResetPassword-module__errorAlert_-0_6aa__",
  "form": "ResetPassword-module__form_-0_6aa__",
  "instructLogo": "ResetPassword-module__instructLogo_-0_6aa__",
  "instructLogoContainer": "ResetPassword-module__instructLogoContainer_-0_6aa__",
  "msgContainer": "ResetPassword-module__msgContainer_-0_6aa__",
  "resetPasswordContainer": "ResetPassword-module__resetPasswordContainer_-0_6aa__",
  "submitBtn": "ResetPassword-module__submitBtn_-0_6aa__",
  "title": "ResetPassword-module__title_-0_6aa__",
  "titleContainer": "ResetPassword-module__titleContainer_-0_6aa__"
};
      