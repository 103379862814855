import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Unauthenticated = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const response = await axios.get('/api/user/fetch-current-user');

      if (response.data.current_user != null) {
        navigate('/');
      }
    }

    fetchCurrentUser();
  }, []);

  return <Outlet />;
};

export default Unauthenticated;