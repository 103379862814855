import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/Card.module.css";

export default {
  "container": "Card-module__container_T5rgPa__",
  "description": "Card-module__description_T5rgPa__",
  "selectedContainer": "Card-module__selectedContainer_T5rgPa__",
  "tag": "Card-module__tag_T5rgPa__",
  "title": "Card-module__title_T5rgPa__",
  "unselectedContainer": "Card-module__unselectedContainer_T5rgPa__"
};
      