import React from 'react';
import { Button, Typography, Tag, Input } from 'antd';
import { 
  LikeOutlined, 
  HeartTwoTone, 
  CheckCircleFilled
} from '@ant-design/icons';
const { Text } = Typography;
const { TextArea } = Input;

import { ReviewFeedbackStep } from '../lib/constants';
import styles from '../../stylesheets/ReviewFeedback.module.css';

const ReviewFeedback = (props) => {
  const tagClassName = (selected) => {
    if (selected) {
      return styles.tag;
    } else {
      return `${styles.tag} ${styles.unselectedTag}`;
    }
  }

  const feedbackQuality = () => {
    return(
      <div className={styles.feedbackQualityContainer}>
        <Text className={styles.feedbackQualityMsg}>
          How was the feedback?
        </Text>
        <Button
          color="primary"
          variant="outlined"
          size="large"
          shape="circle"
          icon={<LikeOutlined />}
          onClick={props.handleSubmitPositiveReview}
          loading={props.submittingPositiveFeedback}
        />
        <Button
          color="primary"
          variant="outlined"
          size="large"
          shape="circle"
          className={styles.practiceAgainButton}
          icon={<LikeOutlined rotate={180} />}
          onClick={props.transitionToTagsStep}
        />
      </div>
    );
  }

  const feedbackTags = () => {
    return(
      <div className={styles.tagsContainer}>
        <div className={styles.tagsTitleContainer}>
          <Text className={styles.tagsTitle}>
            Tell us more. What issues did you experience?
          </Text>
        </div>
        <div className={styles.tagsContainer}>
          {props.feedbackReviewTags.map((tag) => (
            <Tag.CheckableTag
              key={tag.id}
              checked={props.selectedTagIds.includes(tag.id)}
              onChange={(checked) => props.handleTagChange(tag.id, checked)}
              className={tagClassName(props.selectedTagIds.includes(tag.id))}
            >
              {tag.issue}
            </Tag.CheckableTag>
          ))}
        </div>
        <div className={styles.tagsButtonsContainer}>
          <Button 
            size='middle'
            onClick={props.transitionToQualityStep}
          >
            Back
          </Button>
          <div className={styles.rightTagButtonsContainer}>
            <Button 
              size='middle' 
              type='text'
              onClick={props.transitionToDetailsStep}
            >
              Skip
            </Button>
            <Button 
              size='middle' 
              type='primary'
              onClick={props.transitionToDetailsStep}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    );
  }

  const feedbackDetails = () => {
    return(
      <div className={styles.detailsContainer}>
        <div className={styles.detailsTitleContainer}>
          <Text className={styles.detailsTitle}>
            Optionally add specific details:
          </Text>
        </div>
        <TextArea 
          rows={4} 
          placeholder='Step 2 of the core content wasn’t clear. In addition, the AI pulled the wrong quote from my practice attempt, and marked it as yellow when it should have been green.'
          className={styles.details}
          value={props.feedbackReviewDetails}
          onChange={(event) => props.handleDetailsChange(event.target.value)}
        />
        <div className={styles.detailsButtonsContainer}>
          <Button 
            size='middle' 
            onClick={props.transitionToTagsStep}
          >
            Back
          </Button>
          <div className={styles.rightDetailsButtonsContainer}>
            <Button 
              size='middle' 
              type='text'
              loading={props.skippingNegativeReview}
              onClick={props.handleSkipNegativeReview}
            >
              Skip
            </Button>
            <Button 
              size='middle' 
              type='primary'
              loading={props.submittingNegativeReview}
              onClick={props.handleSubmitNegativeReview}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  }

  const confirmationNegative = () => {
    return(
      <div className={styles.confirmationNegativeContainer}>
        <div className={styles.confirmationNegativeCheckContainer}>
          <CheckCircleFilled className={styles.confirmationNegativeCheck} />
        </div>
        <div className={styles.confirmationNegativeTopTextContainer}>
          <Text className={styles.confirmationNegativeTopText}>
            Thanks for the feedback! 
          </Text>
          <HeartTwoTone 
            twoToneColor='#EE9095'
            style={{ fontSize: '24px' }}
          />
        </div>
        <div className={styles.confirmationNegativeBottomTextContainer}>
          <Text className={styles.confirmationNegativeBottomText}>
            We’ve sent it to a human, and we’ll use it to improve the AI for yourself and others.
          </Text>
        </div>
      </div>
    );
  }

  const confirmationPositive = () => {
    return(
      <div className={styles.confirmationPositiveContainer}>
        <div className={styles.confirmationPositiveCheckContainer}>
          <CheckCircleFilled className={styles.confirmationPositiveCheck} />
        </div>
        <div className={styles.confirmationPositiveTextContainer}>
          <Text className={styles.confirmationPositiveText}>
            Thanks, we’re glad it was helpful!
          </Text>
        </div>
      </div>
    );
  }
  const feedbackComponent = () => {
    switch(props.step) {
      case ReviewFeedbackStep.QUALITY:
        return feedbackQuality();
      case ReviewFeedbackStep.TAGS:
        return feedbackTags();
      case ReviewFeedbackStep.DETAILS:
        return feedbackDetails();
      case ReviewFeedbackStep.CONFIRMATION_POSITIVE:
        return confirmationPositive();
      case ReviewFeedbackStep.CONFIRMATION_NEGATIVE:
        return confirmationNegative();
      case ReviewFeedbackStep.NONE:
        return <div/>;
    }
  }

  const containerStyle = () => {
    if (props.step === ReviewFeedbackStep.NONE) {
      return styles.emptyContainer;
    } else {
      return styles.container;
    }
  }

  return(
    <div className={containerStyle()}>
      {feedbackComponent()}
    </div>
  );
}

export default ReviewFeedback;