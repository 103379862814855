import React from 'react';
import { Modal, Typography } from 'antd';
import { AppleFilled, WindowsFilled } from '@ant-design/icons';
const { Title, Link } = Typography;

import styles from '../../stylesheets/OSPermissionsDeniedModal.module.css';

const APPLE_CAMERA_SUPPORT_URL = 'https://support.apple.com/en-ca/guide/mac-help/mchlf6d108da/mac';
const APPLE_MICROPHONE_SUPPORT_URL = 'https://support.apple.com/en-ca/guide/mac-help/mchla1b1e1fe/mac';
const WINDOWS_SUPPORT_URL = 'https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857';

const OSPermissionsDeniedModal = (props) => {
  const handleRefreshPage = () => {
    window.location.reload();
  }

  return(
    <Modal
      open={props.isOpen} 
      onOk={handleRefreshPage}
      closeable={true}
      onCancel={props.handleClose}
      cancelButtonProps={{ className: styles.cancelButton }}
      okText='I have granted these permissions'
      okButtonProps={{ className: styles.okButton }}
      classNames={{
        content: styles.content,
        footer: styles.footer,
      }}
    >
    <Title 
      level={5}
      className={styles.title}
    >
      Just one more step
    </Title>
    <p className={styles.body}>
      Great! You’ve given permission in your browser. Now go to your computer’s systems settings. The next step is to grant permissions there, and then restart your browser.
    </p>
    <div className={styles.macInstructionsContainer}>
      <AppleFilled className={styles.macIcon} />
      <div className={styles.macInstructions}>
        <span>For Mac users: Instructions for granting&nbsp;</span>
        <Link 
          href={APPLE_MICROPHONE_SUPPORT_URL} 
          target='_blank'
          className={styles.link}
        >
          access to microphone
        </Link>&nbsp;&&nbsp;
        <Link 
          href={APPLE_CAMERA_SUPPORT_URL} 
          target='_blank'
          className={styles.link}
        >
          access to camera.
        </Link>
      </div>
    </div>
    <div className={styles.windowsInstructionsContainer}>
      <WindowsFilled className={styles.windowsIcon} />
      <div className={styles.windowsInstructions}>
        <span>For Windows users: Instructions for&nbsp;</span>
        <Link 
          href={WINDOWS_SUPPORT_URL}
          target='_blank'
          className={styles.link}
        >
          granting access to microphone & camera.
        </Link>
      </div>
    </div>
  </Modal>
  )
};

export default OSPermissionsDeniedModal;