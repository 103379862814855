import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Row, Col, Layout } from 'antd';
const { Title } = Typography;
const { Content } = Layout;

import Card from './Card.jsx';
import TopicDetails from './TopicDetails.jsx';
import styles from '../../stylesheets/Overview.module.css';

const Overview = () => {
  const [selectedTopicToken, setSelectedTopicToken] = useState(null);
  const [topics, setTopics] = useState([]);
  const [isLoading, setLoading] = useState(true);
  
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const topicToken = params.get('topicToken');

    if (topicToken) {
      setSelectedTopicToken(topicToken);
    }
  }, []);

  useEffect(() => {
    async function fetchTopics() {
      const response = await axios.get('/api/topic/fetch-all');
      setTopics(response.data.topics);
      setLoading(false);
    }

    fetchTopics();
  }, [])

  const topicCards = () => {
    return topics.map((topic) => {
      return(
        <Card 
          title={topic.title}
          description={topic.description}
          status={topic.status}
          handleClick={handleSelectTopic}
          token={topic.token}
          selected={selectedTopicToken === topic.token}
          key={topic.token}
        />
      )
    })
  }

  const handleSelectTopic = (topicToken) => {
    setSelectedTopicToken(topicToken);

    const newQueryString = `?topicToken=${topicToken}`;

    navigate({
      search: newQueryString,
    });
  }

  const handleCloseDetails = () => {
    setSelectedTopicToken(null);
    navigate({search: ''});
  }

  const layoutConfiguration = () => {
    if (topics.length === 1) {
      return {
        titleColumnOffset: 7,
        showTopicCards: false,
      };
    } else {
      return {
        titleColumnOffset: 3,
        showTopicCards: true,
      }
    }
  }

  const topicDetails = () => {
    if (topics.length === 1) {
      const topic = topics[0];

      return(
        <Col 
          offset={7}
          span={10} 
          className={styles.onlyOneDetailsContainer}
          style={{ paddingLeft: '32px', paddingRight: '32px' }}
        >
          <TopicDetails 
            handleCloseClick={() => {}}
            onlyOneTopic={true}
            title={topic.title}
            description={topic.description}
            subtopics={topic.subtopics}
          />
        </Col>
      );
    }
    
    const selectedTopic = topics.find((topic) => topic.token == selectedTopicToken);
    if (selectedTopicToken !== null && !!selectedTopic) {
      return(
        <Col 
          span={11} 
          offset={0}
          className={styles.detailsContainer}
          style={{ paddingLeft: '32px', paddingRight: '32px' }}
        >
          <TopicDetails 
            handleCloseClick={handleCloseDetails}
            onlyOneTopic={false}
            title={selectedTopic.title}
            description={selectedTopic.description}
            subtopics={selectedTopic.subtopics}
          />
        </Col>
      );
    }

    return null;
  }

  return (
    <Layout>
      <Content
        className={styles.container}
        align="stretch"
      >
        <Row 
          className={styles.titleContainer}
        >
          {
            !isLoading &&
            <Col 
              span={10} 
              offset={layoutConfiguration().titleColumnOffset}
              style={{ paddingLeft: '32px', paddingRight: '32px' }}
            >
              <Title level={3} style={{ marginBottom: 21, marginTop: 40 }}>
                Welcome back! Let’s continue.
              </Title>
            </Col>
          }
        </Row>
        <Row
          className={styles.mainContentContainer}
        >
          {
            layoutConfiguration().showTopicCards &&
              <Col 
                span={10} 
                offset={3}
                style={{ paddingLeft: '32px', paddingRight: '32px' }}
                className={styles.topicCardsContainer}
              >
                {topicCards()}
              </Col>
          }
          {topicDetails()}
        </Row>
      </Content>
    </Layout>
  );
};

export default Overview;