import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/BrowserPermissionsDeniedModal.module.css";

export default {
  "audioIcon": "BrowserPermissionsDeniedModal-module__audioIcon_8z5P7a__",
  "body": "BrowserPermissionsDeniedModal-module__body_8z5P7a__",
  "cancelButton": "BrowserPermissionsDeniedModal-module__cancelButton_8z5P7a__",
  "content": "BrowserPermissionsDeniedModal-module__content_8z5P7a__",
  "footer": "BrowserPermissionsDeniedModal-module__footer_8z5P7a__",
  "iconsContainer": "BrowserPermissionsDeniedModal-module__iconsContainer_8z5P7a__",
  "lockContainer": "BrowserPermissionsDeniedModal-module__lockContainer_8z5P7a__",
  "lockIcon": "BrowserPermissionsDeniedModal-module__lockIcon_8z5P7a__",
  "lockedAudioIcon": "BrowserPermissionsDeniedModal-module__lockedAudioIcon_8z5P7a__",
  "lockedVideoIcon": "BrowserPermissionsDeniedModal-module__lockedVideoIcon_8z5P7a__",
  "okButton": "BrowserPermissionsDeniedModal-module__okButton_8z5P7a__",
  "title": "BrowserPermissionsDeniedModal-module__title_8z5P7a__",
  "videoCameraIcon": "BrowserPermissionsDeniedModal-module__videoCameraIcon_8z5P7a__"
};
      