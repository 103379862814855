import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/GatheringFeedbackPresentation.module.css";

export default {
  "gatheringFeedbackContainer": "GatheringFeedbackPresentation-module__gatheringFeedbackContainer_GzhqzG__",
  "gatheringFeedbackText": "GatheringFeedbackPresentation-module__gatheringFeedbackText_GzhqzG__",
  "gatheringFeedbackTextContainer": "GatheringFeedbackPresentation-module__gatheringFeedbackTextContainer_GzhqzG__",
  "loading": "GatheringFeedbackPresentation-module__loading_GzhqzG__",
  "overlay": "GatheringFeedbackPresentation-module__overlay_GzhqzG__",
  "slideContainer": "GatheringFeedbackPresentation-module__slideContainer_GzhqzG__",
  "slideImage": "GatheringFeedbackPresentation-module__slideImage_GzhqzG__"
};
      