import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/PresentationPractice.module.css";

export default {
  "audioIcon": "PresentationPractice-module__audioIcon_bRt9oW__",
  "audioText": "PresentationPractice-module__audioText_bRt9oW__",
  "beginRecordingBtn": "PresentationPractice-module__beginRecordingBtn_bRt9oW__",
  "container": "PresentationPractice-module__container_bRt9oW__",
  "countDownContainer": "PresentationPractice-module__countDownContainer_bRt9oW__",
  "countDownText": "PresentationPractice-module__countDownText_bRt9oW__",
  "horizontalDivider": "PresentationPractice-module__horizontalDivider_bRt9oW__",
  "overlay": "PresentationPractice-module__overlay_bRt9oW__",
  "slideButton": "PresentationPractice-module__slideButton_bRt9oW__",
  "slideButtonLeft": "PresentationPractice-module__slideButtonLeft_bRt9oW__",
  "slideButtonRight": "PresentationPractice-module__slideButtonRight_bRt9oW__",
  "slideContainer": "PresentationPractice-module__slideContainer_bRt9oW__",
  "slideHidden": "PresentationPractice-module__slideHidden_bRt9oW__",
  "slideVisible": "PresentationPractice-module__slideVisible_bRt9oW__",
  "videoRecorderContainer": "PresentationPractice-module__videoRecorderContainer_bRt9oW__",
  "videoStream": "PresentationPractice-module__videoStream_bRt9oW__",
  "videoStreamContainer": "PresentationPractice-module__videoStreamContainer_bRt9oW__",
  "videoStreamHidden": "PresentationPractice-module__videoStreamHidden_bRt9oW__"
};
      