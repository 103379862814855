import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/CompletedPresentationPractice.module.css";

export default {
  "buttonContainer": "CompletedPresentationPractice-module__buttonContainer_iM5_aa__",
  "completedSessionBody": "CompletedPresentationPractice-module__completedSessionBody_iM5_aa__",
  "completedSessionContainer": "CompletedPresentationPractice-module__completedSessionContainer_iM5_aa__",
  "completedSessionTitle": "CompletedPresentationPractice-module__completedSessionTitle_iM5_aa__",
  "completedSessionWrapper": "CompletedPresentationPractice-module__completedSessionWrapper_iM5_aa__",
  "overlay": "CompletedPresentationPractice-module__overlay_iM5_aa__",
  "practiceAgainButton": "CompletedPresentationPractice-module__practiceAgainButton_iM5_aa__",
  "slideContainer": "CompletedPresentationPractice-module__slideContainer_iM5_aa__",
  "slideImage": "CompletedPresentationPractice-module__slideImage_iM5_aa__"
};
      