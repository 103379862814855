import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/ReviewFeedback.module.css";

export default {
  "confirmationNegativeBottomText": "ReviewFeedback-module__confirmationNegativeBottomText_pM7fvG__",
  "confirmationNegativeCheck": "ReviewFeedback-module__confirmationNegativeCheck_pM7fvG__",
  "confirmationNegativeCheckContainer": "ReviewFeedback-module__confirmationNegativeCheckContainer_pM7fvG__",
  "confirmationNegativeContainer": "ReviewFeedback-module__confirmationNegativeContainer_pM7fvG__",
  "confirmationNegativeTopText": "ReviewFeedback-module__confirmationNegativeTopText_pM7fvG__",
  "confirmationNegativeTopTextContainer": "ReviewFeedback-module__confirmationNegativeTopTextContainer_pM7fvG__",
  "confirmationPositiveCheck": "ReviewFeedback-module__confirmationPositiveCheck_pM7fvG__",
  "confirmationPositiveCheckContainer": "ReviewFeedback-module__confirmationPositiveCheckContainer_pM7fvG__",
  "confirmationPositiveContainer": "ReviewFeedback-module__confirmationPositiveContainer_pM7fvG__",
  "confirmationPositiveText": "ReviewFeedback-module__confirmationPositiveText_pM7fvG__",
  "container": "ReviewFeedback-module__container_pM7fvG__",
  "details": "ReviewFeedback-module__details_pM7fvG__",
  "detailsButtonsContainer": "ReviewFeedback-module__detailsButtonsContainer_pM7fvG__",
  "detailsContainer": "ReviewFeedback-module__detailsContainer_pM7fvG__",
  "detailsTitle": "ReviewFeedback-module__detailsTitle_pM7fvG__",
  "detailsTitleContainer": "ReviewFeedback-module__detailsTitleContainer_pM7fvG__",
  "emptyContainer": "ReviewFeedback-module__emptyContainer_pM7fvG__",
  "feedbackQualityContainer": "ReviewFeedback-module__feedbackQualityContainer_pM7fvG__",
  "feedbackQualityMsg": "ReviewFeedback-module__feedbackQualityMsg_pM7fvG__",
  "rightDetailsButtonsContainer": "ReviewFeedback-module__rightDetailsButtonsContainer_pM7fvG__",
  "rightTagButtonsContainer": "ReviewFeedback-module__rightTagButtonsContainer_pM7fvG__",
  "tag": "ReviewFeedback-module__tag_pM7fvG__",
  "tagsButtonsContainer": "ReviewFeedback-module__tagsButtonsContainer_pM7fvG__",
  "tagsContainer": "ReviewFeedback-module__tagsContainer_pM7fvG__",
  "tagsTitle": "ReviewFeedback-module__tagsTitle_pM7fvG__",
  "tagsTitleContainer": "ReviewFeedback-module__tagsTitleContainer_pM7fvG__",
  "unselectedTag": "ReviewFeedback-module__unselectedTag_pM7fvG__"
};
      