import React from 'react';
import axios from 'axios';
import { 
  Typography,
  Spin,
  Layout,
  Col,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
const { Text, Title } = Typography;
const { Content } = Layout;

import { practiceFeedbackPath } from '../lib/urls';
import styles from '../../stylesheets/GatheringFeedbackPresentation.module.css';

class GatheringFeedbackPresentation extends React.Component {

  componentDidMount() {
    this.fetchWrittenFeedbackIntervalId = setInterval(this.fetchWrittenFeedback, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.fetchWrittenFeedbackIntervalId);
  }
  
  fetchWrittenFeedback = async () => {
    const feedbackToken = this.props.params.feedbackToken;
    const response = await axios.get(`/api/feedback/fetch-written?feedback_token=${feedbackToken}`);

    if (response.data.status == 'COMPLETE') {
      const {
        subtopicToken,
        unitToken,
      } = this.props.params;

      this.props.navigate(practiceFeedbackPath(subtopicToken, unitToken, feedbackToken));
    }
  }

  render() {
    const activeSlide = this.props.practiceScenario.practice_slides.find(slide => slide.order === 1);
    const activeSlideUrl = activeSlide ? activeSlide.url : null;
  
    return(
      <Content>
        <Col span={18}>
          <Title level={3} style={{ marginBottom: '15px' }}>
            {this.props.practiceScenario.title}
          </Title>
          <div className={styles.slideContainer}>
            <div className={styles.overlay} />
            <div className={styles.gatheringFeedbackContainer}>
              <Spin 
                indicator={<LoadingOutlined className={styles.loading} spin />} 
              />
              <div className={styles.gatheringFeedbackTextContainer}>
                <Text className={styles.gatheringFeedbackText}>
                  Gathering feedback...
                </Text>
              </div>
            </div>
            <img 
              className={styles.slideImage}
              src={activeSlideUrl}
            />
          </div>
        </Col>
      </Content>
    )
  }
};

const GatheringFeedbackPresentationWrapper = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const outletContext = useOutletContext();

  return(
    <GatheringFeedbackPresentation
      {...props} 
      params={params} 
      navigate={navigate}
      {...outletContext}
    />
  );
};

export default GatheringFeedbackPresentationWrapper;