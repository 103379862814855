import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
const { Title, Text } = Typography;

import styles from '../../stylesheets/ResetPasswordSuccess.module.css';

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.resetPasswordSuccessContainer}>
        <CheckCircleFilled className={styles.checkMark}/>
        <Title level={2} className={styles.title}>
          Password reset
        </Title>
        <Text className={styles.msg}>
          You can now login with your new password.
        </Text>
        <Button 
          size='large'
          type='primary'
          onClick={() => { navigate('/login') }}
        >
          Go to login page
        </Button>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;