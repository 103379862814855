import React from 'react';
import { Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
const { Title, Text } = Typography;

import styles from '../../stylesheets/ForgotPasswordConfirmation.module.css';

const ForgotPasswordConfirmation = () => {
  return (
    <div className={styles.container}>
      <div className={styles.forgotPasswordConfirmationContainer}>
        <CheckCircleFilled className={styles.checkMark}/>
        <Title level={2} className={styles.title}>
          Check your email
        </Title>
        <Text>
          If an account is registered with that email address, we've sent you a link to reset your password. Please check your inbox and follow the instructions.
        </Text>
      </div>
    </div>
  );
};

export default ForgotPasswordConfirmation;