import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import { CheckCircleFilled, CloseOutlined, RightOutlined } from '@ant-design/icons';
const { Text } = Typography;

import styles from '../../stylesheets/TopicDetails.module.css';
import { SubtopicStatuses } from '../lib/constants';
import { unitPath } from '../lib/urls';

const TopicDetails = (props) => {
  const navigate = useNavigate();

  const subtopicStatusIcon = (status) => {
    if (status == SubtopicStatuses.COMPLETE) {
      return(
        <CheckCircleFilled 
          className={styles.completeIcon} 
          style={{ color: '#2E4A8B' }} 
        />
      );
    } else {
      return <div className={styles.waitingIcon} />;
    }
  }

  const fullOutline = () => {
    return props.subtopics.map((subtopic, index) => {
      return(
        <div 
          className={styles.subtopicContainer}
          key={subtopic.token}
          onClick={() => handleSubtopicClick(subtopic)}
        >
          {subtopicStatusIcon(subtopic.status)}
          <Text className={styles.subtopic}>{index + 1}. {subtopic.title}</Text>
          <RightOutlined className={styles.subtopicArrow} />
        </div>
      );
    });
  }

  const handleSubtopicClick = (subtopic) => {
    navigate(unitPath(subtopic.token, subtopic.first_unit_token, subtopic.first_unit_type));
  }

  return (
    <div className={styles.container}>
      {
        !props.onlyOneTopic &&
          <CloseOutlined 
            className={styles.closeIcon} 
            onClick={props.handleCloseClick}
          />
      }
      <div className={styles.titleContainer}>
        <Text className={styles.title}>
          {props.title}
        </Text>
      </div>
      <div className={styles.descriptionContainer}>
        <Text>{props.description}</Text>
      </div>
      <div className={styles.subtopicTitleContainer}>
        <Text className={styles.subtopicsTitle}>
          Course Outline
        </Text>
      </div>
      <div className={styles.subtopicListContainer}>
        {fullOutline()}
      </div>
    </div>
  );
};

export default TopicDetails;