import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/TopicDetails.module.css";

export default {
  "closeIcon": "TopicDetails-module__closeIcon_c-3yWG__",
  "completeIcon": "TopicDetails-module__completeIcon_c-3yWG__",
  "container": "TopicDetails-module__container_c-3yWG__",
  "descriptionContainer": "TopicDetails-module__descriptionContainer_c-3yWG__",
  "subtopic": "TopicDetails-module__subtopic_c-3yWG__",
  "subtopicArrow": "TopicDetails-module__subtopicArrow_c-3yWG__",
  "subtopicContainer": "TopicDetails-module__subtopicContainer_c-3yWG__",
  "subtopicTitleContainer": "TopicDetails-module__subtopicTitleContainer_c-3yWG__",
  "subtopicsTitle": "TopicDetails-module__subtopicsTitle_c-3yWG__",
  "title": "TopicDetails-module__title_c-3yWG__",
  "titleContainer": "TopicDetails-module__titleContainer_c-3yWG__",
  "waitingIcon": "TopicDetails-module__waitingIcon_c-3yWG__"
};
      