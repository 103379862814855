import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/ForgotPassword.module.css";

export default {
  "container": "ForgotPassword-module__container_dIcHwq__",
  "emailInput": "ForgotPassword-module__emailInput_dIcHwq__",
  "forgotPasswordContainer": "ForgotPassword-module__forgotPasswordContainer_dIcHwq__",
  "form": "ForgotPassword-module__form_dIcHwq__",
  "instructLogo": "ForgotPassword-module__instructLogo_dIcHwq__",
  "instructLogoContainer": "ForgotPassword-module__instructLogoContainer_dIcHwq__",
  "msgContainer": "ForgotPassword-module__msgContainer_dIcHwq__",
  "submitBtn": "ForgotPassword-module__submitBtn_dIcHwq__",
  "title": "ForgotPassword-module__title_dIcHwq__",
  "titleContainer": "ForgotPassword-module__titleContainer_dIcHwq__"
};
      