import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Typography, Button } from 'antd';
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
const { Title, Text } = Typography;

import ReviewFeedback from './ReviewFeedback';
import PracticeSubmitModal from './PracticeSubmitModal';
import { ReviewFeedbackStep, PracticeSubmitModalState } from '../lib/constants';
import { practiceVideoPath, unitPath } from '../lib/urls';
import styles from '../../stylesheets/PracticeFeedback.module.css';
import '../../stylesheets/Feedback.css';

const PracticeFeedback = () => {
  const { subtopicToken, unitToken, feedbackToken } = useParams();
  const { practiceScenario, nextUnit } = useOutletContext();
  const navigate = useNavigate();

  const [transcript, setTranscript] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [practiceSessionSubmitted, setPracticeSessionSubmitted] = useState(false);
  const [feedbackReviewTags, setFeedbackReviewTags] = useState([]);
  const [selectedFeedbackReviewTagIds, setSelectedFeedbackReviewTagIds] = useState([]);
  const [feedbackReviewDetails, setFeedbackReviewDetails] = useState('');
  const [reviewFeedbackStep, setReviewFeedbackStep] = useState(ReviewFeedbackStep.QUALITY);
  const [submittingPositiveReview, setSubmittingPositiveReview] = useState(false);
  const [submittingNegativeReview, setSubmittingNegativeReview] = useState(false);
  const [skippingNegativeReview, setSkippingNegativeReview] = useState(false);
  const [submitModalState, setSubmitModalState] = useState(PracticeSubmitModalState.HIDE);
  const [submittingPracticeSession, setSubmittingPracticeSession] = useState(false);

  const practiceAttemptToken = useRef(null);

  useEffect(() => {
    async function fetchTranscript() {
      const response = await axios.get(`/api/feedback/fetch-written?feedback_token=${feedbackToken}`);
      const practice_attempt = response.data.practice_attempt;
      const feedback = response.data.feedback;

      setTranscript(practice_attempt.transcript);
      setFeedback(feedback.content);

      if (practice_attempt.submitted_at !== null) {
        setPracticeSessionSubmitted(true);
      }

      practiceAttemptToken.current = practice_attempt.token;

      if(feedback.review_submitted) {
        setReviewFeedbackStep(ReviewFeedbackStep.NONE);
      }
    }

    async function fetchFeedbackReviewTags() {
      const response = await axios.get('/api/feedback-review-tags/fetch');
      setFeedbackReviewTags(response.data.feedback_review_tags);
    }

    fetchTranscript();
    fetchFeedbackReviewTags();
  }, []);

  const handleSubmitPracticeSession = async () => {
    setSubmittingPracticeSession(true);

    await axios.post('/api/practice-attempt/submit', {
      practice_attempt_token: practiceAttemptToken.current,
    });

    setSubmittingPracticeSession(false);
    setSubmitModalState(PracticeSubmitModalState.SHOW_SUBMIT_CONFIRMATION)
    setPracticeSessionSubmitted(true);
  }

  const transitionToQualityStep = () => {
    setReviewFeedbackStep(ReviewFeedbackStep.QUALITY)
  }

  const transitionToTagsStep = () => {
    setReviewFeedbackStep(ReviewFeedbackStep.TAGS)
  }

  const transitionToDetailsStep = () => {
    setReviewFeedbackStep(ReviewFeedbackStep.DETAILS)
  }

  const handleFeedbackReviewTagChange = (tagId, checked) => {
    const nextSelectedTagIds = checked
      ? [...selectedFeedbackReviewTagIds, tagId]
      : selectedFeedbackReviewTagIds.filter((id) => id !== tagId);

      setSelectedFeedbackReviewTagIds(nextSelectedTagIds);
  };

  const handleDetailsChange = (value) => {
    setFeedbackReviewDetails(value);
  }

  const handleSubmitPositiveReview = async () => {
    setSubmittingPositiveReview(true);

    const response = await axios.post('/api/feedback/submit-positive-review', {
      feedback_token: feedbackToken,
    });

    setSubmittingPositiveReview(false);
    setReviewFeedbackStep(ReviewFeedbackStep.CONFIRMATION_POSITIVE)
  }

  const handleSkipNegativeReview = async () => {
    setSkippingNegativeReview(true);

    await submitNegativeReview();
    setSkippingNegativeReview(false);
    setReviewFeedbackStep(ReviewFeedbackStep.CONFIRMATION_NEGATIVE)
  }

  const handleSubmitNegativeReview = async () => {
    setSubmittingNegativeReview(true);

    await submitNegativeReview();
    setSubmittingNegativeReview(false);
    setReviewFeedbackStep(ReviewFeedbackStep.CONFIRMATION_NEGATIVE)
  }

  const submitNegativeReview = async () => {
    axios.post('/api/feedback/submit-negative-review', {
      feedback_token: feedbackToken,
      details: feedbackReviewDetails,
      feedback_review_tag_ids: selectedFeedbackReviewTagIds,
    });
  }

  const handlePracticeAgain = () => {
    navigate(practiceVideoPath(subtopicToken, unitToken));
  }

  const handleNextUnit = () => {
    navigate(unitPath(subtopicToken, nextUnit.token, nextUnit.unit_type));
  }

  const handleShowSubmitModal = () => {
    setSubmitModalState(PracticeSubmitModalState.SHOW_SUBMIT_OPTION);
  }

  const handleHideSubmitModal = () => {
    setSubmitModalState(PracticeSubmitModalState.HIDE);
  }

  const separatingLineClass = () => {
    if (reviewFeedbackStep === ReviewFeedbackStep.QUALITY || reviewFeedbackStep === ReviewFeedbackStep.NONE) {
      return styles.separatingLineInvisible;
    } else {
      return styles.separatingLine;
    }
  }

  const practiceSessionSubmittedStatus = () => {
    if (practiceSessionSubmitted) {
      return <span className={styles.submittedStatus}>Submitted</span>;
    } else {
      return <span className={styles.notSubmittedStatus}>Not Submitted</span>;
    }
  }

  if (transcript === null) {
    return <div/>;
  }

  return(
    <div>
      <Title level={3} style={{ marginBottom: '15px' }}>
        {practiceScenario.title}
      </Title>
      <div className={styles.fullContainer}>
        <div className={styles.transcriptContainer}>
          <Title level={4} className={styles.yourPracticeSessionTitle}>
            Your Practice Session
          </Title>
          <div className={styles.statusIndicatorContainer}>
            <span className={styles.statusHeader}>Status: </span>
            {practiceSessionSubmittedStatus()}
          </div>
          <Title level={5} className={styles.transcriptTitle}>
            Transcript
          </Title>
          <Text className={styles.transcriptText}>
            {transcript}
          </Text>
        </div>
        <div className={styles.feedbackContainer}>
          <Markdown
            className='feedback-container'
            skipHtml={false}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          >
            {feedback}
          </Markdown>
        </div>
        <div className={separatingLineClass()}/>
        <ReviewFeedback 
          step={reviewFeedbackStep}
          handleTagChange={handleFeedbackReviewTagChange}
          handleDetailsChange={handleDetailsChange}
          feedbackReviewTags={feedbackReviewTags}
          selectedTagIds={selectedFeedbackReviewTagIds}
          feedbackReviewDetails={feedbackReviewDetails}
          transitionToQualityStep={transitionToQualityStep}
          transitionToTagsStep={transitionToTagsStep}
          transitionToDetailsStep={transitionToDetailsStep}
          handleSubmitPositiveReview={handleSubmitPositiveReview}
          submittingPositiveReview={submittingPositiveReview}
          handleSubmitNegativeReview={handleSubmitNegativeReview}
          submittingNegativeReview={submittingNegativeReview}
          handleSkipNegativeReview={handleSkipNegativeReview}
          skippingNegativeReview={skippingNegativeReview}
        />
        <div className={styles.nextActionBtnContainer}>
          <Button 
            size='large' 
            type='default'
            onClick={handlePracticeAgain}
          >
            Practice again
          </Button>
          {
            practiceSessionSubmitted ?
            <Button 
              size='large' 
              type='primary'
              onClick={handleNextUnit}
              disabled={nextUnit === null}

            >
              Next module
            </Button>
            :
            <Button 
              size='large' 
              type='primary'
              onClick={handleShowSubmitModal}
            >
              Submit practice session
            </Button>
          }
        </div>
      </div>
      <PracticeSubmitModal
        modalState={submitModalState}
        handleSubmitPracticeSession={handleSubmitPracticeSession}
        handleHideModal={handleHideSubmitModal}
        submittingPracticeSession={submittingPracticeSession}
        handleNextUnit={handleNextUnit}
        nextUnitBtnDisabled={nextUnit === null}
      />
    </div>
  );
};

export default PracticeFeedback;