import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/PracticeFeedback.module.css";

export default {
  "feedbackContainer": "PracticeFeedback-module__feedbackContainer_BYVWuW__",
  "fullContainer": "PracticeFeedback-module__fullContainer_BYVWuW__",
  "nextActionBtnContainer": "PracticeFeedback-module__nextActionBtnContainer_BYVWuW__",
  "notSubmittedStatus": "PracticeFeedback-module__notSubmittedStatus_BYVWuW__",
  "separatingLine": "PracticeFeedback-module__separatingLine_BYVWuW__",
  "separatingLineInvisible": "PracticeFeedback-module__separatingLineInvisible_BYVWuW__",
  "statusHeader": "PracticeFeedback-module__statusHeader_BYVWuW__",
  "statusIndicatorContainer": "PracticeFeedback-module__statusIndicatorContainer_BYVWuW__",
  "submittedStatus": "PracticeFeedback-module__submittedStatus_BYVWuW__",
  "transcriptContainer": "PracticeFeedback-module__transcriptContainer_BYVWuW__",
  "transcriptText": "PracticeFeedback-module__transcriptText_BYVWuW__",
  "transcriptTitle": "PracticeFeedback-module__transcriptTitle_BYVWuW__",
  "yourPracticeSessionTitle": "PracticeFeedback-module__yourPracticeSessionTitle_BYVWuW__"
};
      