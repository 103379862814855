import React, { useState, useRef } from 'react';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { Layout, Typography, Button, Row, Col, Spin } from 'antd';
import { 
  LeftOutlined,
  RightOutlined,
  PlayCircleFilled,
  CaretRightOutlined,
  ReloadOutlined,
  SoundFilled,
  MutedFilled,
  PauseOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const { Title, Text } = Typography;
const { Content } = Layout;

import { unitPath } from '../lib/urls';
import styles from '../../stylesheets/WatchUnit.module.css';

const INITIAL_LOADING = 'INITIAL_LOADING';
const NOT_STARTED = 'NOT_STARTED';
const PLAYING = 'PLAYING';
const WAITING = 'WAITING';
const PAUSED = 'PAUSED';
const FINISHED = 'FINISHED';

const WatchUnit = () => {
  const videoRef = useRef(null);
  const { subtopicToken } = useParams();
  const { unit, previousUnit, nextUnit } = useOutletContext();
  const navigate = useNavigate();

  const [videoState, setVideoState] = useState(INITIAL_LOADING);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showInstructions, setShowInstructions] = useState(false);

  const handleLoadedMetadata = () => {
    setDuration(videoRef.current.duration);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
};

  const handlePlay = () => {
    videoRef.current.play();
    setVideoState(PLAYING);
  }

  const handlePause = () => {
    videoRef.current.pause();
    setVideoState(PAUSED);
  }

  const handleVideoLoaded = () => {
    setVideoState(NOT_STARTED);
  }

  const toggleMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  };

  const handleTimelineChange = (event) => {
    const newTime = (event.target.value / 100) * duration;
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);

    if (newTime < duration && videoState === FINISHED) {
      setVideoState(PAUSED);
    } else if (newTime == duration && videoState === PAUSED) {
      setVideoState(FINISHED);
      setShowInstructions(true)
    }
  };

  const handlePlaying = () => {
    setVideoState(PLAYING);
  }

  const handleWaiting = () => {
    setVideoState(WAITING);
  }

  const handleVideoEnd = () => {
    setVideoState(FINISHED);
    setShowInstructions(true)
  }

  const handleReplay = () => {
    setVideoState(PLAYING);
    setCurrentTime(0);
    videoRef.current.currentTime = 0;
    videoRef.current.play();
  }

  const videoControlPrimaryButton = () => {
    if (videoState == NOT_STARTED || videoState == PAUSED) {
      return(
        <CaretRightOutlined 
          className={styles.smallPlayBtn} 
          onClick={handlePlay}
        />
      );
    } else if (videoState == FINISHED) {
      return(
        <ReloadOutlined 
          className={styles.replayBtn} 
          onClick={handleReplay}
        />
      )
    } else {
      return(
        <PauseOutlined 
          className={styles.smallPlayBtn} 
          onClick={handlePause}
        />
      );
    }
  }

  const videoLoadingClassName = () => {
    if (videoState == INITIAL_LOADING) {
      return styles.videoLoading;
    } else {
      return styles.hidden;
    }
  }

  const videoContainerClassName = () => {
    if (videoState == INITIAL_LOADING) {
      return styles.hidden;
    } else {
      return styles.video;
    }
  }

  const displayTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds) % 60;
    
    const paddedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    
    return `${minutes}:${paddedSeconds}`;
  }

  const handlePreviousUnitClick = () => {
    navigate(unitPath(subtopicToken, previousUnit.token, previousUnit.unit_type));
  }

  const handleNextUnitClick = () => {
    navigate(unitPath(subtopicToken, nextUnit.token, nextUnit.unit_type));
  }

  const watchUnit = unit;

  return (
    <Content>
      <Row>
        <Col 
          span={14}
          style={{ paddingLeft: '32px', paddingRight: '32px' }}
        >
          <Title level={3} style={{ marginBottom: '15px' }}>
            {watchUnit.title}
          </Title>
          <div className={styles.videoContainer}>
            {
              videoState == NOT_STARTED &&
                <div>
                  <div className={styles.overlay} />
                  <div className={styles.playBtn} />
                  <PlayCircleFilled 
                    className={styles.playIcon} 
                    onClick={handlePlay}
                  />
                </div>

            }
            {
              videoState == FINISHED &&
                <div className={styles.finishedOverlay}>
                  {
                    nextUnit !== null &&
                    [
                      <div className={styles.finishedTextContainer} key='finishedTextContainer'>
                        <Text className={styles.upNextText}>Up Next</Text>
                        <br/>
                        <Text className={styles.nextCourseText}>{nextUnit.title}</Text>
                      </div>,
                      <Button 
                        type='primary' 
                        className={styles.continueBtn}
                        size='large'
                        onClick={handleNextUnitClick}
                        key='continueButton'
                      >
                        Continue
                      </Button>

                    ]
                  }
                </div>
            }
            <div className={videoLoadingClassName()}>
              <Spin 
                indicator={<LoadingOutlined className={styles.initialLoadingSpinner} spin />} 
              />
            </div>
            <video
              ref={videoRef}
              onTimeUpdate={handleTimeUpdate}
              onLoadedMetadata={handleLoadedMetadata}
              onLoadedData={handleVideoLoaded}
              onWaiting={handleWaiting}
              onPlaying={handlePlaying}
              onEnded={handleVideoEnd}
              controls={false}
              className={videoContainerClassName()}
            >
              <source src={watchUnit.video_signed_url} type="video/mp4" />
            </video>
            {
              videoState === WAITING &&
                <Spin 
                  className={styles.waitingSpinnerContainer}
                  indicator={<LoadingOutlined className={styles.waitingSpinner} spin />} 
                />
            }
          </div>
          <div className={styles.videoControls}>
            {videoControlPrimaryButton()}
            {
              isMuted ?
                <MutedFilled 
                  className={styles.muteBtn}
                  onClick={toggleMute}
                />
                :
                <SoundFilled 
                  className={styles.soundBtn}
                  onClick={toggleMute}
                />
            }
            <div className={styles.videoTimeContainer}>
              <div className={styles.videoCurrentTimeContainer}>
                <Text className={styles.videoTimeText}>
                  {displayTime(currentTime)}
                </Text>
              </div>
              <div className={styles.videoTimelineContainer}>
                <input
                  type='range'
                  min='0'
                  max='100'
                  value={(currentTime / duration) * 100 || 0}
                  onChange={handleTimelineChange}
                  className={styles.videoTimeline}
                />
              </div>
              <div className={styles.videoEndTimeContainer}>
                <Text className={styles.videoTimeText}>
                  {displayTime(duration)}
                </Text>
              </div>
            </div>
          </div>
        </Col>
        <Col
          span={10}
          style={{ paddingLeft: '32px', paddingRight: '32px' }}
        >
          <div className={styles.rightContainer}>
            <div className={styles.subtopicNavigationContainer}>
              <Button 
                type="link" 
                icon={<LeftOutlined />}
                onClick={handlePreviousUnitClick}
                disabled={previousUnit === null}
              >
                Previous
              </Button>
              <Button 
                type="link" 
                icon={<RightOutlined />}
                iconPosition="end"
                onClick={handleNextUnitClick}
                disabled={nextUnit === null}
              >
                Next
              </Button>
            </div>
            <div className={styles.instructionsContainer}>
              {
                showInstructions &&
                <Markdown 
                  className='instructions-container'
                  remarkPlugins={[remarkGfm]}
                >
                  {watchUnit.instructions}
                </Markdown>
              }
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
};

export default WatchUnit;