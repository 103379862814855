import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/RecordingControlNotStarted.module.css";

export default {
  "container": "RecordingControlNotStarted-module__container_lc7eqW__",
  "deleteButton": "RecordingControlNotStarted-module__deleteButton_lc7eqW__",
  "deleteContainer": "RecordingControlNotStarted-module__deleteContainer_lc7eqW__",
  "pauseButton": "RecordingControlNotStarted-module__pauseButton_lc7eqW__",
  "pauseContainer": "RecordingControlNotStarted-module__pauseContainer_lc7eqW__",
  "timeElapsedContainer": "RecordingControlNotStarted-module__timeElapsedContainer_lc7eqW__",
  "timeElapsedText": "RecordingControlNotStarted-module__timeElapsedText_lc7eqW__",
  "verticalDivider": "RecordingControlNotStarted-module__verticalDivider_lc7eqW__"
};
      