import "esbuild-css-modules-plugin-ns-css:app/assets/stylesheets/OSPermissionsDeniedModal.module.css";

export default {
  "body": "OSPermissionsDeniedModal-module__body_5vtCaG__",
  "cancelButton": "OSPermissionsDeniedModal-module__cancelButton_5vtCaG__",
  "content": "OSPermissionsDeniedModal-module__content_5vtCaG__",
  "footer": "OSPermissionsDeniedModal-module__footer_5vtCaG__",
  "link": "OSPermissionsDeniedModal-module__link_5vtCaG__",
  "macIcon": "OSPermissionsDeniedModal-module__macIcon_5vtCaG__",
  "macInstructions": "OSPermissionsDeniedModal-module__macInstructions_5vtCaG__",
  "macInstructionsContainer": "OSPermissionsDeniedModal-module__macInstructionsContainer_5vtCaG__",
  "okButton": "OSPermissionsDeniedModal-module__okButton_5vtCaG__",
  "title": "OSPermissionsDeniedModal-module__title_5vtCaG__",
  "windowsIcon": "OSPermissionsDeniedModal-module__windowsIcon_5vtCaG__",
  "windowsInstructions": "OSPermissionsDeniedModal-module__windowsInstructions_5vtCaG__",
  "windowsInstructionsContainer": "OSPermissionsDeniedModal-module__windowsInstructionsContainer_5vtCaG__"
};
      